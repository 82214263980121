import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {Titular} from '../models/titular';

@Injectable({
    providedIn: 'root'
})
export class PersonaService {
    constructor(private httpClient: HttpClient) {
    }
    getTitularByCip(cip: number): Observable<Titular> {
        const url = environment.apiEndPointBackend + '/v1/titular/getTitularByCip/' + String(cip);
        return this.httpClient.get(url).pipe(
            catchError(this.handleError<any>('getTitularByCip', undefined)));
    }
    isAdminByCip(cip: number): Observable<boolean> {
        const url = environment.apiEndPointBackend + '/v1/titular/isAdminByCip/' + String(cip);
        return this.httpClient.get(url).pipe(
            catchError(this.handleError<any>('isAdminByCip', undefined)));
    }

    private handleError<T>(operation = 'operation', result?: T): any {
        return (error: any): Observable<T> => {
            console.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }
}
