<h2 mat-dialog-title>INFORMACIÓN IMPORTANTE...!!!</h2>
<mat-dialog-content class="mat-typography">
    <div>
        <button class="box-btn signup-btn" (click)="currentPdfIndex = (currentPdfIndex > 0) ? currentPdfIndex - 1 : 0">Antes</button>
        &nbsp;
        <button class="box-btn signup-btn" (click)="currentPdfIndex = (currentPdfIndex < publicaciones.length - 1) ? currentPdfIndex + 1 : publicaciones.length - 1">Siguiente</button>
    </div>

    <pdf-viewer [src]="publicaciones[currentPdfIndex]?.url"
                [render-text]="true"
                [original-size]="false"
                style="display: block; width: 100%; height: 100vh;">
    </pdf-viewer>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="box-btn signup-btn" mat-dialog-close><mat-icon class="mat-18">close</mat-icon>Cerrar</button>
</mat-dialog-actions>
