<app-navbar></app-navbar>
<section class="slider-area-2">
    <div class="home-slider owl-carousel owl-theme">
        <div class="single-slider single-slider-bg-1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-12 text-center">
                                <div class="slider-content one">
                                    <h1>Seguro de Sepelio</h1>
                                    <p>Institución de apoyo solidario y sentido humanista, creado para atender los casos de fallecimiento de sus afiliados.</p>
                                    <div class="slider-btn text-center">
<!--                                        <a href="https://fossep.ejercito.mil.pe/login" class="box-btn" target="_blank">SISTEMA DE AFILICIÓN</a>-->
                                        <a routerLink="/documentos-afiliado" class="box-btn border-btn">OTRAS GESTIONES</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="single-slider single-slider-bg-2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-12 text-center">
                                <div class="slider-content one">
                                    <h1>Sepulturas y Cremaciones</h1>
                                    <p>Un Seguro de Sepelio para ti y tu familia, que les brinda tranquilidad para cuándo más lo necesiten.</p>
                                    <div class="slider-btn text-center">
<!--                                        <a href="https://fossep.ejercito.mil.pe/login" class="box-btn" target="_blank">SISTEMA DE AFILICIÓN</a>-->
                                        <a routerLink="/documentos-afiliado" class="box-btn border-btn">OTRAS GESTIONES</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="single-slider single-slider-bg-3">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-12 text-center">
                                <div class="slider-content one">
                                    <h1>Servicios Funerarios</h1>
                                    <p>Estamos contigo en el momento que más nos necesitas.</p>
                                    <div class="slider-btn text-center">
<!--                                        <a href="https://fossep.ejercito.mil.pe/login" class="box-btn" target="_blank">SISTEMA DE AFILICIÓN</a>-->
                                        <a routerLink="/documentos-afiliado" class="box-btn border-btn">OTRAS GESTIONES</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--<div class="info-area pt-100 pb-70">-->
<!--    <div class="container">-->



<!--        <div class="row">-->
<!--            <div class="col-lg-4 col-sm-4">-->
<!--                &nbsp;-->
<!--            </div>-->
<!--            <div class="col-lg-4 col-sm-4">-->
<!--                <div class="single-info">-->
<!--                    <div class="info-img">-->
<!--                        <img src="assets/videos/instructivos/I0.jpg" alt="info" />-->
<!--                    </div>-->
<!--                    <div class="content">-->
<!--                        <h3><i class="flaticon-info"></i> Video Institucional</h3>-->
<!--                        <div class="arrow">-->
<!--                            <i class="bx bx-play" (click)="verVideo(0)"></i>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-lg-4 col-sm-4">-->
<!--                &nbsp;-->
<!--            </div>-->

<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-info">
                    <div class="info-img">
                        <img src="assets/images/info/i1.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-info"></i> Nosotros</h3>
                        <div class="arrow">
                            <a routerLink="/about"><i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-info">
                    <div class="info-img">
                        <img src="assets/images/info/i2.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-support"></i> Nuesta Historia</h3>
                        <div class="arrow">
                            <a routerLink="/about"> <i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-info si-30">
                    <div class="info-img">
                        <img src="assets/images/info/i3.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-goal"></i>Organización</h3>
                        <div class="arrow">
                            <a routerLink="/about"> <i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-info">
                    <div class="info-img">
                        <img src="assets/videos/instructivos/I0.jpg" alt="info" />
                    </div>
                    <div class="content">
                        <h3><i class="flaticon-info"></i> Video Institucional</h3>
                        <div class="arrow">
                            <i class="bx bx-play" (click)="verVideo(0)"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<section class="home-service-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestros Servicios</h2>
            <p>Un Seguro de Sepelio para ti y tu familia, que les brinda tranquilidad para cuándo más lo necesiten.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/inhumacion.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Servicio Funerario de Inhumación</h3>
                        <p>Comprende la atención funeraria, el ataúd, el espacio en cementerio.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Quieres saber más?</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/cremacion.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Servicio Funerario de Cremación</h3>
                        <p>Comprende la atención funeraria, el ataúd, la cremación y entrega de cenizas.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Quieres saber más?</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/reembolso.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Reembolsos</h3>
                        <p>Los afiliados al FOSSEP que residan en lugares muy apartados, en provincias donde no hay agencias funerarias.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Quieres saber más?</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!--<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img *ngIf="flagVideo" src="assets/images/historia.jpg" alt="choose" />

                    <div class="technology-video">
                        <button *ngIf="flagVideo" (click)="verVideo(0)" type="button" class="btn btn-success btn-sm"><i class='bx bx-play'></i>Ver video</button>
                        <iframe *ngIf="!(flagVideo)" [src]="safeSrc" class="example-container" width="600" height="350" frameborder="0" ></iframe>

                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Servicios de Sepelio</h2>
                    <span>Inhumación</span>
                    <p style="text-align: justify">Servicio funerario de inhumación comprende todo lo necesario para una atención funeraria “de primera”. Desde el ataúd hasta el espacio (compartido) en los camposantos que el FOSSEP tenga convenios.</p>
                    <span>Cremación</span>
                    <p style="text-align: justify">El servicio funerario de cremación comprende el proceso de incineración. La cremación que se concluye con la entrega de las cenizas en una urna. Este servicio requiere mayores trámites: área de salud, autorización para cremación, carta notarial de poder.</p>
                </div>
            </div>
        </div>
    </div>
</section>-->
<section class="home2-choose-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Convenios</h2>
            <p>El seguro de Sepelio te brinda la cobertura y asistencia de las más prestigiosas empresas del mercado.</p>
        </div>

        <div class="row">
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <img src="assets/images/gallery/jardinesdelapaz.jpg" alt="info" />
                    </div>
                    <h3>Jardines de la Paz</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <img src="assets/images/gallery/mafreLogo.jpg" alt="info" />
                    </div>
                    <h3>Mafre</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <img src="assets/images/gallery/campoFe.jpg" alt="info" />
                    </div>
                    <h3>Campo Fe</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <img src="assets/images/gallery/ParqueDelRecuerdo.jpg" alt="info" />
                    </div>
                    <h3>Parque del Recuerdo</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <img src="assets/images/gallery/ParqueEterno.jpg" alt="info" />
                    </div>
                    <h3>Parque Eterno</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <img src="assets/images/gallery/JARDINESDELARESURECCION.jpg" alt="info" />
                    </div>
                    <h3>Jardines de la Resurección</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <img src="assets/images/gallery/JARDINESDESANJUAN.jpg" alt="info" />
                    </div>
                    <h3>Jardines de San Juan</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <img src="assets/images/gallery/INMACULADA.jpg" alt="info" />
                    </div>
                    <h3>Inmaculada</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <img src="assets/images/gallery/PARQUESDELPARAISO.jpg" alt="info" />
                    </div>
                    <h3>Parques del Paraiso</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <img src="assets/images/gallery/ESPERANZAETERNA.jpg" alt="info" />
                    </div>
                    <h3>Esperanza Eterna</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <img src="assets/images/gallery/JARDINESDELEDEN.jpg" alt="info" />
                    </div>
                    <h3>Jardínes del Edén</h3>
                </div>
            </div>
<!--            <div class="col-lg-2 col-sm-4">-->
<!--                <div class="single-choose">-->
<!--                    <div class="icon">-->
<!--                        <img src="assets/images/gallery/JARDINESDELRECUERDO.jpg" alt="info" />-->
<!--                    </div>-->
<!--                    <h3>Ataudes América</h3>-->
<!--                </div>-->
<!--            </div>-->
        </div>

    </div>
</section>
<section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Afiliación</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>¿Quienes pueden afiliarse?</h3>
                        <p>Todo el personal que labora en el ejército del Perú (militar y/o civil).</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>¿A quienes puedo afiliar?</h3>
                        <p>A su cónyuge, hijos y padres, que ninguno pase de 75 años, en el año que realizan la afiliación.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Familiares Adicionales</h3>
                        <p>Hermano, nieto, hijastro y conviviente.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>Aporte Mensual</h3>
                        <p >Grupo 1: S/ {{environment.montoGrupo1}}</p>
                        <p >Grupo 2: S/ {{environment.montoGrupo2}}</p>
                        <p >Hijos mayores de 28 AÑOS: S/{{environment.montoGrupo1}}.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-contact-area home-2-contact pb-100">
    <div class="container">
        <br>
        <div class="section-title">
            <h2>Ubicación</h2>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="content">
                    <img src="assets/images/banner-image/fossep.jpg">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>

                    <div class="content">
                        <h3>Corre Electrónico</h3>
                        <p>mesadepartes@fossep.com</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-location-plus'></i>
                    </div>

                    <div class="content">
                        <h3>Dirección</h3>
                        <p>Av. De la Policía Cdra. 2 Jesús María, Lima, Perú</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <div class="content">
                        <h3>Teléfonos</h3>
                        <p>958465839 - 463-1560</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
