<app-navbar></app-navbar>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Detalles de los Servicios</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li class="active">Detalles de los Servicios</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="scrives-item-2 mt-4 ">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="services-img mb-qc">
                        <img src="../../../../assets/images/company/company-img.jpg" alt="company" />
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="single-pricing">
                        <div class="pricing-top-heading">
                            <h3>SERVICIO FUNERARIO CON INHUMACIÓN.</h3>
                        </div>
                        <ul>
                            <li><i class="bx bx-badge-check"></i> Ataúd de madera modelo Estándar.</li>
                            <li><i class="bx bx-badge-check"></i> Carroza.</li>
                            <li><i class="bx bx-badge-check"></i> Capilla ardiente.</li>
                            <li><i class="bx bx-badge-check"></i> Vehículo porta flores.</li>
                            <li><i class="bx bx-badge-check"></i> Traslado local Velatorio y camposanto.</li>
                            <li><i class="bx bx-badge-check"></i> Salón velatorio (según disponibilidad).</li>
                            <li><i class="bx bx-badge-check"></i> Cargadores uniformados y/o vvhh de acompañamiento (12 psj).</li>
                            <li><i class="bx bx-badge-check"></i> Tumba en cementerio privado (compartida) o Nicho de beneficencia o municipal.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="scrives-item-2 mt-4 ">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="single-pricing">
                        <div class="pricing-top-heading">
                            <h3>SERVICIO FUNERARIO CON CREMACIÓN</h3>
                        </div>
                        <ul>
                            <li><i class="bx bx-badge-check"></i> Ataúd de madera modelo Estándar.</li>
                            <li><i class="bx bx-badge-check"></i> Capilla ardiente.</li>
                            <li><i class="bx bx-badge-check"></i> Trámites ante RENIEC.</li>
                            <li><i class="bx bx-badge-check"></i> Traslado local al velatorio y lugar de cremación.</li>
                            <li><i class="bx bx-badge-check"></i> Salón velatorio.</li>
                            <li><i class="bx bx-badge-check"></i> Cargadores uniformados y/o vvhh de acompañamiento (12 psj).</li>
                            <li><i class="bx bx-badge-check"></i> Uso de horno crematorio.</li>
                            <li><i class="bx bx-badge-check"></i> Urna.</li>
                        </ul>
                    </div>

                </div>
                <div class="col-lg-6">
                    <div class="services-img mb-qc">
                        <img src="assets/images/23B.jpg" alt="Image">
                    </div>
                </div>


            </div>
        </div>
        <div class="scrives-item-2 mt-4 ">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="services-img mb-qc">
                        <img src="assets/images/despedida.jpg" alt="Image">
                    </div>
                </div>

                <div class="col-lg-6">
                    <h3>REEMBOLSOS</h3>
                    <h5 class="m-0"><strong>¿Quiénes aplican al reembolso?</strong></h5>
                    <p style="text-align: justify">Los afiliados al <strong>FOSSEP</strong> que residan en lugares muy apartados, en localidades de provincias donde no hay agencias funerarias contratadas que cubran dicha zona, podrá emplear los servicios que brindan las agencias funerarias o sus similares en dichas zonas, pudiendo para tal efecto, ser reintegrados dichos gastos de sepelio, hasta por el monto máximo vigente fijado por el Consejo Directivo para el lugar. </p>
                    <h5 class="m-0"><strong>¿Cómo aplican al reembolso?</strong></h5>
                    <p style="text-align: justify">Los afiliados al <strong>FOSSEP</strong> deberán presentar los originales de las facturas o boletas de venta a nombre del <strong>FOSSEP</strong>, acompañados de una solicitud dirigida al Administrador en un plazo no mayor de <strong>180 días calendario</strong>, a partir de la fecha de deceso. </p>
                    <h5 class="m-0"><strong>IMPORTANTE:</strong></h5>
                    <p style="text-align: justify">El Reembolso en <strong>Lima</strong> solo corresponderá al uso de espacios, sepulturas o paquetes de cremación adquiridos en vida o en necesidad futura, el monto será establecido por el consejo directivo del <strong>FOSSEP</strong>. </p>
                    <p style="text-align: justify">En <strong>Lima</strong> el uso de agencias funerarias ajenas a las contratadas por el <strong>FOSSEP</strong>, no generarán reembolso por motivo alguno.</p>
                    <p style="text-align: justify">El <strong>FOSSEP</strong> previa evaluación de la situación podrá reintegrar los gastos por sepelio del titular y/o beneficiario que residan en el extranjero, hasta por el monto máximo, fijado por el Consejo Directivo.</p>
                    <p style="text-align: justify">Los servicios de <strong>inhumación o cremación</strong>, pueden ser parciales si el afiliado ya cuenta con sepultura o atención funeraria adquiridos con anterioridad.<strong> No hay reembolso alguno por dichos conceptos</strong></p>
                    <p style="text-align: justify"><strong>Las boletas deben estar a nombre del fossep o de la persona que solicitara el reembolso</strong> siempre y cuando esté dentro del grupo familiar.</p>
                </div>
            </div>
        </div>
        <div class="scrives-item-2 mt-4 ">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="services-img mb-qc">
                        <img src="assets/images/Ree1.jpg" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="services-img mb-qc">
                        <img src="assets/images/Ree2.jpg" alt="Image">
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

