export const environment = {
    production: false,
    firebase: {
        apiKey: 'AIzaSyDMGW7Y0Jktpc9X9itqPdv5csAB_dvrlaI',
        authDomain: 'fossep-faae4.firebaseapp.com',
        databaseURL: 'https://fossep-faae4.firebaseio.com',
        projectId: 'fossep-faae4',
        storageBucket: 'fossep-faae4.appspot.com',
        messagingSenderId: '577575842555',
        appId: '1:577575842555:web:8913db1e42ddb7a7d3b65e',
        measurementId: 'G-7S4TRP76ZS'
    },
    codeAuthUserNotFound: 'auth/user-not-found',
    desAuthUserNotFound: 'No hay ningún registro de usuario que corresponda a este identificador. El usuario puede haber sido eliminado',
    desNotFoundDatainDB: 'Usuario con acceso correcto, pero sin información en la Base de Datos. Consulte a la Oficina Principal',
    /*apiEndPointBackend: 'http://localhost:8080',*/
    apiEndPointBackend: 'https://fossep-faae4.rj.r.appspot.com',
    pathStorageBucketVouchersPago: 'vouchersPago/',
    urlReglamento: 'https://firebasestorage.googleapis.com/v0/b/fossep-faae4.appspot.com/o/formatoAfiliacion%2FREGLAMENTO%20FOSSEP%202022.pdf?alt=media&token=a92457ee-5a5c-4f70-96c6-20281a45ded3',
    montoGrupo1: '17.00',
    montoGrupo2: '26.00'
};
