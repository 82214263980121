import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {Publicacion} from '../models/publicacion';

@Injectable({
  providedIn: 'root'
})
export class PublicacionService {

  constructor(private httpClient: HttpClient) { }

    getPublicacionDisponibles(): Observable<Publicacion[]> {
        return this.httpClient.get(environment.apiEndPointBackend + '/v1/publicacion/disponibles').pipe(
            catchError(this.handleError<any>('getPublicacionDisponibles', undefined)));
    }

    private handleError<T>(operation = 'operation', result?: T): any {
        return (error: any): Observable<T> => {
            console.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }
}
