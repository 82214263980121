<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-sm-0">
                <div class="logo">
                    <a routerLink="/"><img src="assets/images/virgen.png" width="60" alt="logo" /></a>
                </div>
            </div>

            <div class="col-lg-8 col-sm-8 text-right pr-0">
                <div class="header-content-right">
                    <ul class="header-contact">
                        <li><a href="tel:+958465839"><i class="bx bxs-phone-call"></i> 958 465 839</a></li>
                        <li><a href="tel:+014631560"><i class="bx bxs-phone-call"></i> 01 463 1560</a></li>
                        <li><a href="mailto:mesadepartes@fossep.com"><i class="bx bxs-envelope"></i>mesadepartes@fossep.com</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4 text-right pl-0">
                <div class="header-content-right">
                    <ul class="header-social">
                        <li><a href="https://www.facebook.com/fossep.solidariodesepelio" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="mailto:mesadepartes@fossep.com" target="_blank"> <i class="bx bxs-envelope"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="{{navbarClass}}">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/images/logo.png" alt="logo" /></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav text-left">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Inicio</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Nosotros</a>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Servicios</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Servicios</a></li>

                                <li class="nav-item"><a routerLink="/solutions-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Detalles Servicios</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Afiliación</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/case-studies" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Proceso Afiliación</a></li>

                                <li class="nav-item"><a routerLink="/documentos-afiliado" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Documentos Afiliación</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Galeria</a>
                        </li>

                        <li class="nav-item">
                            <a  href="{{environment.urlReglamento}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" target="_blank">Reglamento</a>
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link">Contactanos </a></li>
                        <li class="nav-item">
                            <a routerLink="/sign-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Descuentos</a>
                        </li>
                    </ul>
                </div>

                <div class="nav-right">
                   <!-- <form>
                        <div class="input-group">
                            <input type="text" class="form-control search" placeholder="Search..." />
                        </div>

                        <button type="submit">
                            <i class="bx bx-search"></i>
                        </button>
                    </form>-->
                </div>

                <div class="nav-btn">
                    <a routerLink="/sign-in" class="box-btn">Ver Descuentos</a>
                </div>
            </nav>
        </div>
    </div>
</div>
