import {Component, Inject, OnInit} from '@angular/core';
import {Publicacion} from '../../../models/publicacion';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-informacion',
  templateUrl: './informacion.component.html',
  styleUrls: ['./informacion.component.scss']
})
export class InformacionComponent implements OnInit {
    currentPdfIndex = 0;
    constructor(public dialogRef: MatDialogRef<InformacionComponent>,
                @Inject(MAT_DIALOG_DATA) public publicaciones: Publicacion[] ) {
  }

  ngOnInit(): void {

  }

}
