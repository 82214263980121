<app-navbar></app-navbar>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contactanos</h2>
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Contactanos</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>

                    <div class="content">
                        <h3>Corre Electrónico</h3>
                        <p>mesadepartes@fossep.com</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-location-plus'></i>
                    </div>

                    <div class="content">
                        <h3>Dirección</h3>
                        <p>Av. De la Policía Cdra. 2 Jesús María, Lima, Perú</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>

                    <div class="content">
                        <h3>Teléfonos</h3>
                        <p>958465839 - 463-1560</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-contact-area home-2-contact pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Ubicación</h2>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="content">
                  <img src="assets/images/banner-image/fossep.jpg">
                </div>
            </div>
        </div>
    </div>
</section>
