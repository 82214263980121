import {Component, OnInit} from '@angular/core';
import {PublicService} from '../../../services/public.service';
import {Location} from '../../../models/location';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {VideosComponent} from "./videos/videos.component";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-home-two',
    templateUrl: './home-two.component.html',
    styleUrls: ['./home-two.component.scss']
})
export class HomeTwoComponent implements OnInit {

    constructor(private dialog: MatDialog,
                private publicService: PublicService) {
        this.flagVideo = true;
        this.getCementerios();
    }
    flagVideo: boolean;
    lat: number;
    lng: number;
    zoom: number;
    convenioLocation: Location[] = [];

    public readonly environment = environment;

    ngOnInit(): void {
    }

    verVideo(option: number): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = 'auto';
        dialogConfig.data = { name: option};
        const dialogRef = this.dialog.open(VideosComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
        });
    }



    getCementerios() {
        this.lat = -11.961995;
        this.lng =  -77.125170;
        this.zoom = 6;
        this.publicService.getCementerios().subscribe(
            (data) => {
                this.convenioLocation = data;
            }
        );

    }
}
