<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/images/virgen.png" alt="logo" width="100"/></a>
                    </div>
                    <p>"Confia en nosotros, tus momentos dificiles"</p>

                   <!-- <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest' ></i></a></li>
                    </ul>-->
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Nuestros Servicios</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/">Inhumación</a></li>
                        <li><a routerLink="/">Cremación</a></li>
                        <li><a routerLink="/">Reembolsos</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="content">
                    <h3>Acceso Rápido</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/solutions">Servicios</a></li>
                        <li><a routerLink="/solutions-details">Detalles Servicios</a></li>
                        <li><a routerLink="/case-studies">Proceso Afiliación</a></li>
                        <li><a routerLink="/documentos-afiliado">Documentos Afiliación</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contactenos</h3>
                    <ul class="footer-list foot-social">
                        <li><a href="tel:+01958465839"><i class="bx bx-mobile-alt"></i>958 465 839</a></li>
                        <li><a href="tel:+014631560"><i class="bx bx-phone"></i>01 463 1560</a></li>
                        <li><a href="mailto:mesadepartes@fossep.com"><i class="bx bxs-envelope"></i>mesadepartes@fossep.com</a></li>
                        <li><i class="bx bxs-map"></i>Av. De la Policía Cdra. 2 Jesús María.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                   &nbsp;
                </div>

                <div class="col-lg-6">
                    <p class="right"><a href="https://www.linkedin.com/in/oscar-alfredo-chafloque-tampeck-a35194110/" target="_blank">Copyright @2025 Desarrollado por SpartanCode. All Rights Reserved by</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>
