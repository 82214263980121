<app-navbar></app-navbar>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Documentos Afiliación</h2>
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Documentos Afiliación</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Formatos para Afiliación</h2>
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table table-sm">
                    <thead class="thead-light">
                    <tr>
                        <th scope="col">Documento</th>
                        <th scope="col">Descargar Archivo</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let i of this.listaFormatoAfiliacion; let z = index">
                        <th scope="row">{{i.descripcion}}</th>
                        <td><a mat-mat-raised-button color="primary" *ngIf="i.url" href="{{i.url}}" target="_blank"><i class="bx bx-cloud-download"></i>Ver Documento</a></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</section>

